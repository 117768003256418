@font-face {
  font-family: 'Oleo Script';
  src: url('/src/assets/fonts/oleo-script-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('/src/assets/fonts/oleo-script-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/src/assets/fonts/oleo-script-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/src/assets/fonts/oleo-script-v9-latin-regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('/src/assets/fonts/oleo-script-v9-latin-regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/src/assets/fonts/oleo-script-v9-latin-regular.svg#svgFontName') format('svg'); /* Legacy iOS */
}

.sk-chase {
    width: 60px;
    height: 50px;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
    display: inline-block;
}
  
.sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    animation: sk-chase-dot 2.0s infinite ease-in-out both; 
}
  
.sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #000;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
}
  
  .sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
  .sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }
  
  @keyframes sk-chase {
    100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4); 
    } 100%, 0% {
      transform: scale(1.0); 
    } 
  }

.active-boder {
  outline: 5px auto -webkit-focus-ring-color !important;
}

.validator-error {
    color: #ca1237d9 !important;
    font-size: .875rem;
    background: #ffeded;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 1.5rem;
    margin-top: -1.2rem;;
    padding: .2rem;
}

.question-check{
  margin-right: -20px; 
  margin-top: -17px;
}

.card-body-fit{
  margin-top: -20px;
  padding-bottom: .5rem !important;
}

.quest-cards-bg{

  // background-color: floralwhite;
  // background-color: whitesmoke;
  background-color: aliceblue;
  // background-color: #f0f8ff94∫;
  border: none !important;
}

.card-margin{
  margin: .3rem !important;
  -webkit-box-shadow: 10px 10px 5px -7px rgba(159,167,204,1);
  -moz-box-shadow: 10px 10px 5px -7px rgba(159,167,204,1);
  box-shadow: 10px 10px 5px -7px rgba(159,167,204,1);
}

.custom-padding{
  padding-right: 7px;
  padding-left: 7px;
}

.answer{
  color:#4dc388;
  font-style: italic;
}

.card-body-fit, .modal-body, .card-body-fit p, .modal-body p{
  font-size: 0.875rem !important;
  font-weight: 300;
  margin-bottom: 0;
}

// .modal-body  p {
//   font-size: 0.875rem !important;
// }



.modal-header-bg{
  background: rgb(17,205,239);
  background: linear-gradient(90deg, rgba(17,205,239,1) 0%, rgba(15,92,181,1) 90%);
}

.close > span:not(.sr-only){
  color: white !important;
}

.cursor{
  cursor: pointer;
}

h2 { 
  color: #3cb7ef; 
  font-family: 'Oleo Script', cursive; 
  font-size: 25px; 
  font-weight: bold; 
  line-height: 32px; 
  margin: 0 0 18px; 
  text-shadow: 1px 0 0 #fff; 
}

.header-title{

  color: white; 
  font-family: 'Oleo Script', cursive; 
  font-size: 28px; 
  font-weight: normal; 
  line-height: 32px; 
  margin: 0 0 18px; 
  text-shadow: 1px 0 0 #fff;
  letter-spacing: 1px;

}

.table-success{
  background-color: $success;
}
.quest-cards-bg ::-webkit-scrollbar {
  width: 0px;
}

.scroll { 
  overflow-y: scroll;
  height: 9rem;
  overflow-x: hidden;
} 

